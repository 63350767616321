/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import Auth from '@aws-amplify/auth'
// import { setUser } from './src/utils/auth'
import Amplify from '@aws-amplify/core'
import config from './src/aws-exports'
// import { initializeIcons } from '@uifabric/icons'

// import './src/styles/theme'
// import './src/styles/global'
import React from 'react'

import theme from './src/styles/theme'
import global from './src/styles/global'

import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Global styles={global} />
    {element}
  </ThemeProvider>
)

export const onRouteUpdate = (state, page, pages) => {
  Amplify.configure(config)
  //   initializeIcons()

  //   Auth.currentAuthenticatedUser()
  //     .then(user => {
  //       const userInfo = {
  //         ...user.attributes,
  //         username: user.username,
  //       }
  //       setUser(userInfo)
  //     })
  //     .catch(err => {
  //       window.localStorage.setItem('gatsbyUser', null)
  //     })
}
