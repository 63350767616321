const theme = {
  defaultFontStyle: { fontFamily: "'Poppins', sans-serif", fontWeight: '300' },
  breakpoints: ['37.5em', '56.25em', '75em', '112.5em'], // [600px, 900px,1200px,1800px]
  fontSizes: [12, 14, 16, 24, 32, 48, 64],
  colors: {
    themeDarker: '#8f5f00',
    themeDark: '#c28100',
    themeDarkAlt: '#e69900',

    themePrimary: '#FFAA00',
    themeSecondary: '#ffb41f',
    themeTertiary: '#ffcc66',

    themeLight: '#ffe5b3',
    themeLighter: '#fff1d6',
    themeLighterAlt: '#fffcf5',

    black: '#3e3e3e',
    neutralDark: '#565656',
    neutralPrimary: '#272727',
    neutralPrimaryAlt: '#868686',
    neutralSecondary: '#9d9d9d',
    neutralTertiary: '#b5b5b5',

    neutralTertiaryAlt: '#c0c4c8',
    neutralQuaternaryAlt: '#d1d5da',
    neutralLight: '#e1e5ea',
    neutralLighter: '#F5F9FE',
    neutralLighterAlt: '#eef3f8',
    white: '#ffffff',

    red: '#F93E3E',
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
    },
  },
}

export default theme

// import { loadTheme } from 'office-ui-fabric-react/lib/Styling'

// loadTheme({
//   palette: {
// themeDarker: '#8f5f00',
// themeDark: '#c28100',
// themeDarkAlt: '#e69900',

// themePrimary: '#FFAA00',
// themeSecondary: '#ffb41f',
// themeTertiary: '#ffcc66',

// themeLight: '#ffe5b3',
// themeLighter: '#fff1d6',
// themeLighterAlt: '#fffcf5',

//     black: '#3e3e3e',
//     neutralDark: '#565656',
//     neutralPrimary: '#272727',
//     neutralPrimaryAlt: '#868686',
//     neutralSecondary: '#9d9d9d',
//     neutralTertiary: '#b5b5b5',

//     neutralTertiaryAlt: '#c8c8c8',
//     neutralQuaternaryAlt: '#dadada',
//     neutralLight: '#eaeaea',
//     neutralLighter: '#f4f4f4', // f4f4f4
//     neutralLighterAlt: '#f8f8f8',
//     white: '#ffffff',

//     yellow: '#FFCD2B',
//     yellowLight: '#FFF4AF',
//     yellowDark: '#8C7018',

//     redDark: '#8C2323',
//     red: '#F93E3E',
//     redLight: '#FEE0E0',

//     greenDark: '#018C66',
//     green: '#46AB8F',
//     greenLight: '#D7F9EC',
//   },
// })
