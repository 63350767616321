/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7tlrzkl3lfedjkd2yrclxhvyrq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "api616cdc2a",
            "endpoint": "https://bshlvjbwoj.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:293cc0d6-f007-49a1-91e7-da3962a46dd2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_k1vB6X5Wh",
    "aws_user_pools_web_client_id": "7dummcsvvv4opj5itr7ugd5vek",
    "oauth": {
        "domain": "todineclient1e1d46a4-1e1d46a4-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.2dine.io/redirect-auth/,http://localhost:3000/redirect-auth/,https://dev.d2vo3t8dlzp15r.amplifyapp.com/redirect-auth/",
        "redirectSignOut": "https://www.2dine.io/,http://localhost:3000/,https://dev.d2vo3t8dlzp15r.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_user_files_s3_bucket": "todineclientd73d8fe0ce3b4f8cb21511b043639770155807-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "65570b9bbb784484add536f6912f720d",
    "aws_mobile_analytics_app_region": "us-east-1",
    "predictions": {
        "convert": {
            "translateText": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "en",
                    "targetLanguage": "es"
                }
            }
        }
    },
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Notification-todine-prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
