import { css } from '@emotion/core'

export default css`
  @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,600,700&display=swap');

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  #root {
    overflow-x: hidden;
    height: 100vh;
  }

  html {
    height: 100%;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    line-height: 1.6;
    color: #565656;
    min-height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  #___gatsby {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  div[role='group'][tabindex] {
    display: flex;
    flex-direction: column;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    position: initial !important;
    width: fit-content;
    max-height: 90%;
    border: none !important;
    border-radius: 5px;
    padding: 12px !important;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
      rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  }
`

// import { mergeStyles } from 'office-ui-fabric-react/lib/Styling'

// mergeStyles({
//   selectors: {
//     ':global(*)': {
//       margin: 0,
//       padding: 0,
//     },
//     ':global(*), :global(*)::before, :global(*)::afte': {
//       boxSizing: 'inherit',
//     },
//     ':global(#root)': {
//       height: '100%',
//     },
//     ':global(html)': {
//       boxSizing: 'border-box',
//       fontSize: '72.5%',
//     },
//     ':global(body)': {
//       fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", "Helvetica Neue", sans-serif`,
//       fontWeight: 400,
//       overflow: 'hidden',
//       fontSize: '1.2rem',
//       height: '100vh',
//     },
//     ':global(#___gatsby)': {
//       height: '100%',
//     },
//   },
// })
